<template>
	<div class="item-header">
		<span class="name">{{ name || 'No name' }}</span>
		<span class="timestamp">{{ timestamp }}</span>
		<span :class="['icon', 'icon-' + type]">{{ type }}</span>
	</div>
</template>

<script>
export default {
	name: 'Item-header',
	props: ['timestamp', 'type', 'name'],
};
</script>

<style lang="scss" scoped>
.item-header {
	display: grid;
	grid-template-columns: auto 170px 100px;
	position: relative;
	.name {
		text-transform: uppercase;
		font-weight: bold;
		padding: 8px 4px;
		margin: auto;
		margin-left: 0;
	}
	.timestamp {
		margin: auto;
		margin-right: 0;
		padding: 8px 4px;
	}
}

.icon {
	position: absolute;
	right: 5px;
	top: 5px;
	font-weight: normal;
	text-transform: uppercase;
	/* width: 20px; */
	/* height: 20px; */
	padding: 2px 5px;
	border-radius: 5px;
	background-color: #ccc;
	color: #fff;
}

.icon-dump {
	background-color: orange;
}

.icon-vars {
	background-color: green;
}
</style>
