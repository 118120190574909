<template>
	<div class="item-trace" v-html="getTrace"></div>
</template>

<script>
export default {
	name: 'Item-trace',
	props: ['content'],

	computed: {
		getTrace() {
			return Object.values(this.content)
				.map((row) => {
					const args = JSON.stringify(row.args);
					const argsStripped = args.substring(1, args.length - 1);
					return `${row.file} (${row.line}) :: ${row.class || ''}${row.type || ''}${row.function || ''}(${argsStripped})`;
				})
				.join('<br>');
		},
	},
};
</script>

<style lang="scss" scoped>
.item-trace {
	padding: 4px;
	background-color: #464646;
	color: #aaa;
	font-family: monospace;
	font-size: 12;
}
</style>
