<template>
	<div class="item">
		<ItemHeader :timestamp="item.timestamp" :type="item.type" :name="item.name"></ItemHeader>
		<ItemOutput v-if="item.output" :output="item.output"></ItemOutput>
		<div class="tabs">
			<a href="#" @click="activeTab = 'trace'" :class="{ active: activeTab == 'trace' }">Trace</a>
			<a href="#" @click="activeTab = 'request'" :class="{ active: activeTab == 'request' }">Request</a>
			<a href="#" @click="activeTab = 'headers'" :class="{ active: activeTab == 'headers' }">headers</a>
		</div>
		<div class="tab-output">
			<ItemTrace v-if="activeTab == 'trace'" :content="item.backtrace"></ItemTrace>
			<ItemRequest v-if="activeTab == 'request'" :content="item.request"></ItemRequest>
			<ItemHeaders v-if="activeTab == 'headers'" :content="item.headers"></ItemHeaders>
		</div>
	</div>
</template>

<script>
import ItemHeader from './item-header.vue';
import ItemOutput from './item-output.vue';
import ItemTrace from './item-trace.vue';
import ItemRequest from './item-request.vue';
import ItemHeaders from './item-headers.vue';

export default {
	name: 'Item',
	props: ['item'],
	data() {
		return {
			activeTab: 'trace',
		};
	},
	components: {
		ItemHeader,
		ItemOutput,
		ItemTrace,
		ItemRequest,
		ItemHeaders,
	},
	created() {
		console.log(this.item);
	},
};
</script>

<style lang="scss" scoped>
.item {
	border: 2px solid #333;
	border-radius: 5px;
	margin-bottom: 3px;
	.tabs {
		display: grid;

		grid-template-columns: 1fr 1fr 1fr;
		place-items: center stretch;
		text-align: center;
		a {
			padding: 5px;
			// margin: auto;
			text-decoration: none;
			color: #eee;
			text-transform: uppercase;
			font-weight: 400;
			background: #929292;
			&.active {
				color: #333;
				background: #fff;
				font-weight: bolder;
			}
		}
	}
}
</style>
