<template>
	<div class="container">
		<header>
			<h2>REMOTEDUMPER</h2>

			<div class="types">
				<label v-for="type in allTypes" :key="type" :class="['type type-' + type]">
					{{ type }}
					<input type="checkbox" :name="type" v-model="types" :value="type" />
				</label>
			</div>

			<button @click="clearEvents" class="btn">CLEAR</button>

			<Spinner :size="15" v-show="loading"></Spinner>
			<div id="connected" :style="{ backgroundColor: connectedColor }"></div>
		</header>

		<ul id="events">
			<Item v-for="(item, i) in filteredEvents" :key="hash(item.output) + item.timestamp" :item="item"></Item>
		</ul>
	</div>
</template>

<script>
import { io } from 'socket.io-client';
import Spinner from './components/Spinner.vue';
import Item from './components/item.vue';

export default {
	name: 'App',
	data() {
		return {
			events: [],
			connectedColor: 'red',
			types: [],
			allTypes: {},
			loading: false,
		};
	},
	components: {
		Item,
		Spinner,
	},
	created() {
		const socket = io();

		socket.on('connect', () => {
			this.connectedColor = 'green';
		});

		socket.on('disconnect', () => {
			this.connectedColor = 'red';
		});

		socket.on('dump', (msg) => {
			this.events.unshift(msg.data);
			if (!this.allTypes[msg.data.type]) {
				this.allTypes[msg.data.type] = msg.data.type;
				this.types.push(msg.data.type);
			}
		});
	},
	methods: {
		hash(str) {
			let hash = 0,
				i,
				chr;

			if (str.length === 0) return hash;

			for (i = 0; i < str.length; i++) {
				chr = str.charCodeAt(i);
				hash = (hash << 5) - hash + chr;
				hash |= 0; // Convert to 32bit integer
			}
			return hash;
		},
		clearEvents() {
			this.events.splice(0);
			this.types.splice(0);
			this.allTypes = {};
		},
	},
	computed: {
		filteredEvents() {
			return this.events.filter((i) => this.types.includes(i.type));
		},
	},
};
</script>

<style lang="scss" scoped>
header {
	position: relative;
	display: grid;
	grid-template-columns: 150px auto 100px 25px 25px;
	width: 100%;
	h2 {
		margin: 0;
	}
}
.types {
	margin: auto;
	margin-right: 20px;
}
#connected {
	margin: auto;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: red;
}

ul {
	list-style-type: none;
	padding: 0;
}

.btn {
	padding: 2px 5px;
	margin: auto;
}

.type {
	text-transform: uppercase;
	padding: 2px 5px;
	border-radius: 5px;
	background-color: #ccc;
	color: #fff;
	margin-left: 10px;
}

.type-dump {
	background-color: orange;
}

.type-vars {
	background-color: green;
}
</style>
